@import "shared.scss";
@import "_custom.scss";
@import "components.scss";

$light-background-color: #fdfdfd;
$light-text-color: #141313;
$dark-background-color: #141414;
$dark-text-color: #d3d3d3;
// Define color variables
$background-dark: #171717;
$background-darker: #141414;
$background-darkest: #1b1b1b;
$background-dark-alt: #242526;
$background-dark-alt2: #1d1d1d;
$background-dark-alt3: #1f1f1f;
$background-light-gray:#fafafa;
$border-color-dark: #242424;
$border-color-alt: #242424;

$text-color-light: #d3d3d3;
$text-color-light-alt: #d3d3d3;
$text-color-placeholder: #d3d3d3;
$primary-text: #1677ff;
$secondary-text: #000000e0;

$hover-background: #4b4b4b;

$shadow-none: 0px 0px 0px #171717;

$text-color: #d3d3d3;

/* Base styles using SCSS variables */
.app-container {
  transition: background-color 0.3s, color 0.3s;

  &[data-theme="light"] {
    background-color: $light-background-color;
    color: $light-text-color;
  }

  &[data-theme="dark"] {
    background-color: $dark-background-color;
    color: $dark-text-color;
  }
}
.copy-from-select-selector{
  border: 1px solid #707070 !important;
}
.copy-from-select-selection-placeholder{
 color:#707070 !important;
}
.copy-from-select-arrow{
  color:#707070 !important;
}
.metadata-container .product-build-form-field-item{
  width: 85% !important;
}
.metadata-container div:last-child{
  width: 100% ;
}
.metadata-container{
  width: 100% !important;
}
.ant-tag-volcano{
  cursor: pointer;
}
.test-config-value {
  align-items: start !important;
}
button:focus {
  outline: none;
}
.ant-btn:not(:disabled):focus-visible {
  outline: none;
}
.not-selected {
  color: $secondary-text;
}
.top_nav .ant-tabs-tab:hover a {
  color: $primary-text;
}
.top_nav .ant-tabs-tab-active a {
  color: $primary-text !important;
}
.top_nav .ant-tabs-tab {
  padding: 0 !important;
  height: fit-content;
}
.top_nav .ant-tabs-tab a {
  color: $secondary-text;
}
.top_nav .ant-tabs-tab a > div {
  padding: 8px 16px !important;
}

.tab-ellipsis {
}
/* Example of extending other Ant Design components */
.light-mode .ant-layout,
.light-mode .ant-menu {
  background-color: $light-background-color;
  color: $light-text-color;
}

.dark-mode .ant-layout,
.dark-mode .ant-menu {
  background-color: $dark-background-color;
  color: $dark-text-color;
}

.filter-component{
  margin: 0 !important;
  gap: 10px;
  padding: 12px;
  padding-bottom: 8px;
}

.filter-component input , .ant-select-selector{
  border: 1px solid #eeeff2 !important
}

.filter-component .ant-col{
  padding: 0px !important;
}

.layout .site-layout-content {
  background: $background-light-gray !important;
}

.site-layout-content-box{
  padding: 0 !important;
}

.dropdown-wrapper{
  border: 1px solid #eeeff2 !important;
}
.breadcrumb-container {
  border: 1px solid #eeeff2 !important;
}

.layout-header-right-container .ant-btn-compact-item{
  border: 1px solid #eeeff2 !important;
}

// Dark mode styles
.dark-mode {
  .layout-header-right-container .ant-btn-compact-item{
    border: 1px solid $background-dark-alt !important;
  }
  .dropdown-wrapper{
    border: 1px solid $background-dark-alt !important;
  }
  .breadcrumb-container {
    background: $background-dark;
    border: 1px solid $background-dark-alt !important;
  }
  .layout-header-container{
    background: $background-dark-alt;
    border: 1px solid $background-dark-alt !important;
  }
  .main-sidebar-container{
    background: $background-dark-alt !important;
  }
  .main-sidebar-children-container{
    background: $background-dark;
  }
  .expand-toggle-button{
    background: $background-dark-alt !important;
    border: 1px solid $background-dark !important;
  }
  .layout-separator{
    background: $background-dark-alt;
  }
  .table-layout-container{
    background: $background-dark-alt !important;
    border: 1px solid $background-dark-alt !important;
  }
  .form-layout-container{
    background: $background-dark-alt !important;
    border: 1px solid $background-dark-alt !important;
  }
  .filter-component{
    background: $background-dark-alt !important;
    border: 1px solid $background-dark-alt !important;
  }
  .card-style-none {
    border: none !important;
    padding: 0;
    margin: 0;
  }
  .ant-breadcrumb-link {
    color: #f0f0f0;
  }
  .editor-preview-preview {
    background-color: $background-dark-alt;
  }
  background: $background-dark;
  .fs-name {
    background-color: $background-dark-alt;
    color: $dark-text-color !important;
  }
  .panel-container {
    background-color: $dark-background-color;
    color: $text-color-light;
  }

  .ace-jsoneditor .ace_scroller {
    background: $background-dark !important;
  }
  .ace-jsoneditor .ace_string {
    color: $dark-text-color !important;
  }

  .ace-jsoneditor .ace_variable {
    color: $dark-text-color !important;
  }
  .ace-jsoneditor .ace_gutter {
    background: $background-dark !important;
    color: $dark-text-color !important;
  }
  .bg-light {
    background-color: $dark-background-color !important;
  }
  .card {
    border: 1px solid $border-color-alt;
  }
  .jsoneditor-menu > button:disabled {
    background-color: $light-background-color;
  }
  .layout .site-layout-content {
    background: $background-dark !important;
  }

  .layout .ant-layout-header {
    background-color: $background-dark-alt;
    padding-bottom: 0;
  }

  .custom-divider {
    border-block-start: 1px solid $background-dark-alt !important;
    margin-bottom: 0 !important;
  }

  .layout .logo {
    cursor: pointer;
  }

  .custom-card {
    box-shadow: $shadow-none;
    border: 1px solid $background-dark-alt;
  }

  .label-wapper > p {
    color: $text-color-light !important;
    background-color: $background-darker !important;
  }

  .layout .footer {
    background-color: $background-dark-alt !important;
  }

  .list-products-thead > tr > th {
    background-color: $background-dark-alt2 !important;
  }

  .ant-pagination-item {
    border: 1px solid $border-color-alt;
  }
  .ant-divider-horizontal {
    border-block-start: none;
  }

  tr,
  th,
  td {
    border-bottom: 0.004rem solid $border-color-alt !important;
  }
  .jsoneditor-treepath-element {
    color: $text-color-light;
  }

  .total-records-number {
    color: $text-color-light;
  }

  div.jsoneditor-readonly {
    color: $text-color-light;
  }

  .common-tag-color {
    background-color: $background-dark-alt;
    color: $text-color-light;
  }

  .common-button {
    background-color: $background-dark-alt;
    color: $text-color-light;
    border: 1px solid $border-color-alt;
  }

  .ant-btn-primary {
    background-color: $background-dark-alt;
    color: $text-color-light;
    border: 1px solid $border-color-alt;
  }
  .ant-card-bordered {
    border: 1px solid $border-color-alt;
  }

  .ant-card-head {
    border-bottom: 1px solid $border-color-alt;
  }

  .mask-input {
    background-color: $background-darker;
    color: $text-color-light;
    border: 1px solid $border-color-dark;

    &::placeholder {
      color: $background-dark;
    }

    &:hover {
      border-color: #808080;
    }
  }

  .list-products-filter-dropdown {
    border: 1px solid $border-color-dark;
  }

  .anticon-more {
    color: $text-color;
  }

  .ant-layout-sider {
    background: $background-dark !important;
  }

  .jsoneditor-menu {
    background-color: $background-dark-alt;
    color: $text-color;
    border-bottom: 1px solid $border-color-dark;
  }

  .jsoneditor {
    border: 1px solid $border-color-dark;
  }

  .jsoneditor-navigation-bar {
    background-color: $background-dark-alt3;
    border-bottom: 1px solid $border-color-dark;
  }

  div.jsoneditor-tree {
    background-color: $background-darkest;
  }

  div.jsoneditor-field {
    color: $text-color;
  }

  .jsoneditor-contextmenu,
  .jsoneditor-text {
    color: $text-color;
  }

  div.jsoneditor-field[contenteditable="true"]:hover {
    background-color: $hover-background;
    border: 1px solid $hover-background;
  }

  tr.jsoneditor-highlight {
    background-color: $background-dark-alt3;
  }

  .jsoneditor-frame {
    background-color: $background-dark-alt3;
    padding: 0 5px;

    > input {
      background-color: $background-dark-alt3;
      color: $text-color;
    }
  }

  .jsoneditor-menu > button:disabled {
    opacity: 1;
  }

  .jsoneditor-contextmenu .jsoneditor-menu button:hover {
    background-color: $background-dark-alt;
    color: $text-color;
  }

  .ant-breadcrumb a,
  .anticon svg,
  .ant-pagination-item > a,
  .ant-space-item,
  .ant-breadcrumb-separator,
  .ant-empty-description {
    color: $text-color;
  }
  .ant-message-notice-success .anticon svg {
    color: #52c41a !important;
  }
  .ant .ant-btn {
    border: 1px solid $border-color-dark;
  }
  .ant-select-selector {
    border: 1px solid $border-color-dark !important;
  }
  // .ant-tabs-tab {
  //   border: 1px solid $border-color-dark !important;
  // }
  .ant-collapse {
    border: 1px solid $border-color-dark !important;
  }
  .ant-collapse-content {
    border-top: 1px solid $border-color-dark !important;
  }

  .ant-empty-image > svg {
    color: $text-color;
  }

  .ant-collapse-item .ant-input {
    border: 1px solid $border-color-dark !important;
  }
  .ant-input-outlined {
    border: 1px solid $border-color-dark !important;
  }
  .ruleGroup {
    background: $background-dark !important;
    border-color: $background-darker !important;
  }
  .ruleGroup .rule {
    background: $background-darker !important;
  }

  .ruleGroup-combinators,
  .ruleGroup-addRule,
  .ruleGroup-addGroup,
  .rule-fields,
  .rule-operators,
  .rule-value {
    background: $background-darker !important;
  }
  .ant-btn-link {
    color: $dark-text-color;
  }

  .ant-input {
    color: $text-color;

    &::placeholder {
      color: $text-color-placeholder;
    }
  }

  .ant-picker-outlined {
    border: 1px solid $border-color-dark;
  }
  .ant-picker-input > input {
    color: $text-color;

    &::placeholder {
      color: $text-color-placeholder;
    }
  }

  .ant-select-selection-placeholder {
    color: $text-color-placeholder;
  }

  .ant-notification-notice {
    background-color: $background-dark-alt;
    color: $text-color !important;

    &-message,
    &-description {
      color: $text-color !important;
    }
  }
}
