/*// Theme Colors */

$blobstationWhite: #ffffff;
$blobstationBlack: #000000;

$blobstationPrimary1: #2E8096;

$blobstationSecondary1: #252B33;


/* Font weight */
$font-light: 300;
$font-regular: 400;
$font-normal: 500;
$font-semi-bold: 600;
$font-bold: 700;
$extra-bold: 800;

/* Font Size */
$fontsize-9: 9px;

/* padding */
$padding-zero: 0;

/* margin */
$margin-zero: 0;

/*dropdown */
$dropdown-menu-text: capitalize;

/* Button Theming */
$btn-background-color: $blobstationSecondary1;

/*Input text box */
$input-border-regular: 1px solid #b1b1b1 !important;

/* Screen size and media Queries*/
$screen-xsm-min: 374px;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1824px;
$screen-xxxl-min: 2300px;

/* header */
$main-header-bg: #f7f7f7;
$main-height-min: 74px;
$header-logo: 118px;

/* footer */
$footer-bg: #6E6E6E !important;

/* Mixin  */
/*--------------------Media query-----------------------*/
@mixin xsm {
    @media (min-width: #{$screen-xsm-min}) {
        @content;
    }
}

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

@mixin xxxl {
    @media (min-width: #{$screen-xxxl-min}) {
        @content;
    }
}

/* Fonts */