.menu-collapsed .ant-menu-title-content{
  display: contents;
}

.menu-collapsed {
    justify-content: center;
    display: flex !important;
  }

.ant-menu-title-content a:hover{
  text-decoration: none;
}