/* MonacoEditor.scss */
.message {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 14px;
    color: '#d8000c';
    padding: '5px';
    align-items: 'center';
}

.valid {
    background-color: #e4ffdd;
    border-top: 1px solid #73d800;
}
.error{
    background-color: #ffdddd;
    border-top: 1px solid #d8000c;
}
.monoco-code-editor{
    position: relative;
    height: 100%;
}