@import "./../../Scss/shared.scss";
.ant-modal-content .modal-testMessage {
  width: 100px;
}

.span-title-testMessage {
  font-weight: 500;
  font-size: 20px;
}

.span-noTest-testMessage {
  font-size: 15px;
  text-align: "end";
  width: 280px;
  display: "block";
}

.list-item-testMessage {
  padding: 10px;
  margin: 10px;
}

.total-records-container {
  display: flex;
  justify-content: end;
}

.total-records-text {
  margin-right: 5px; /* Optional: Adds some space between the text and the number */
}

.total-records-number {
  color: #1677ff;
  width: 30px;
  display: flex;
  justify-content: center;
}
